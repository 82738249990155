<template>
  <div>
    <van-nav-bar
      title="调价信息"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="adjust-price">
      <div>
        <div>
          <div style="overflow: hidden auto">
            <div>
              <!---->
              <div class="news-header">
                <div class="pricelog-content">
                  <h1>商品变动</h1>
                  <p>商品调价信息</p>
                </div>
              </div>

              <!-- <vue-seamless-scroll
                :data="tableData"
                class="seamless-warp"
                :class-option="optionLeft"
                ref="seamlessScroll"
              >
                <div class="scrollNew">
                  <div
                    class="scrollWaper"
                    v-for="(item, index) of tableData"
                    :key="index"
                  >
                    <router-link :to="{ path: '/detail/' + item.id }">
                      <div class="scrollimg"></div>
                      <div class="scrollName">{{ item.productName }}</div>
                      <div class="scrollMoney">
                        <div style="display: flex">
                          <div>{{ item.productName }}</div>
                        </div>
                        <div class="addCard">+</div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </vue-seamless-scroll> -->

              <div class="list-box">
                <div
                  class="list-item"
                  v-for="(item, index) in tableData"
                  :key="index"
                  @click="toProductDetails(item.productId)"
                >
                  <img :src="item.productImg" class="img-show" />
                  <div class="pricelog-content">
                    <div class="title">
                      {{ item.productName }}
                    </div>
                    <div class="pricelog-time">
                      <span>{{ item.createTime }}</span>
                    </div>
                    <div class="other">
                      <div class="mpricelog-price">
                        <span class="now"> {{ item.priceAfter }} </span>
                        <!-- <span class="face">14.00</span> -->
                        <span class="icon"></span>
                        <span class="change">{{ item.priceFront }}</span>
                      </div>

                      <div>
                        <el-tag
                          type="danger"
                          effect="dark"
                          size="small"
                          v-if="item.priceChangesStatus == 1"
                        >
                          <img
                            src="@/assets/image/zhangjia.png"
                            style="width: 25px"
                          />
                          涨价
                        </el-tag>

                        <el-tag
                          type="success"
                          effect="dark"
                          size="small"
                          v-else-if="item.priceChangesStatus == 2"
                        >
                          <img
                            src="@/assets/image/jiangjia.png"
                            style="width: 25px"
                          />
                          降价
                        </el-tag>

                        <el-tag
                          effect="dark"
                          size="small"
                          v-else-if="item.priceChangesStatus == 3"
                        >
                          商品上架
                        </el-tag>

                        <el-tag
                          type="warning"
                          effect="dark"
                          size="small"
                          v-else-if="item.priceChangesStatus == 4"
                        >
                          商品下架
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </div>
                <!---->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryProductMonitorLog } from "@/api/product";
// import Pagination from "@/components/Pagination";
// import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  components: {
    // vueSeamlessScroll,
  },
  data() {
    return {
      tableData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },

  created() {
    this.initData();
  },

  methods: {
    /**
     * 查询卡组信息
     */
    initData() {
      var then = this;
      new Promise((resolve, rejust) => {
        queryProductMonitorLog(this.queryParam)
          .then((res) => {
            then.tableData = res.result.list;
            then.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },

    toProductDetails(id) {
      this.$router.push(`/product_detail/${id}`);
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.adjust-price {
  width: 100%;
  height: 100%;
  /* position: fixed;
  top: 0;
  left: 0; */
  background: #f5f7fa;
}

.adjust-price {
  width: 100%;
  height: 100%;
}

.adjust-price .list-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.625rem 0;
}

.adjust-price .list-box .list-item {
  width: 95%;
  height: 5.9375rem;
  background: #ffffff;
  border-radius: 0.46875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 0.6875rem;
  box-sizing: border-box;
}

.adjust-price .list-box .list-item .img-show {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 0.625rem;
}

.adjust-price .list-box .list-item .pricelog-content {
  flex: 1;
  display: flex;
  height: 4.6875rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.375rem;
}

.adjust-price .list-box .list-item .pricelog-content .title {
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1e2331;
  max-width: 15.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adjust-price .list-box .list-item .pricelog-content .pricelog-time {
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b6bac2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.adjust-price .list-box .list-item .pricelog-content .pricelog-time .form-item {
  display: flex;
}

.adjust-price .list-box .list-item .pricelog-content .other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .now {
  font-weight: 700;
  color: #f84d43;
  font-size: 1.125rem;
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .now:before {
  content: "\a5";
  color: #f84d43;
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .face {
  margin-right: 0.8125rem;
  line-height: 1.125rem;
  margin-left: 0.3125rem;
  font-weight: 400;
  color: #d2d5dd;
  font-size: 0.75rem;
  text-decoration: line-through;
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .face:before {
  content: "\a5";
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .icon {
  display: inline-block;
  width: 0.4375rem;
  height: 0.59375rem;
  background-image: url(/umobile/assets/icon_down-497b8c8f.svg);
  background-size: 0.4375rem 0.59375rem;
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .up {
  background-image: url(/umobile/assets/icon_rise-06e83f99.svg);
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .change {
  font-size: 0.8125rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #22b371;
}

.adjust-price
  .list-box
  .list-item
  .pricelog-content
  .other
  .mpricelog-price
  .change:before {
  content: "\ffe5";
  color: inherit;
  font-size: 0.8125rem;
}

.adjust-price .list-box .list-item .pricelog-content .other .btn {
  /* width: 3.125rem; */
  height: 1.5625rem;
  background: linear-gradient(90deg, #fe8a56 0%, #ff504b 100%);
  border-radius: 0.78125rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adjust-price .list-box .list-item .pricelog-content .other .btn:active {
  transform: scale(1.1);
}

.adjust-price .list-box .list-item .pricelog-content .other .stop {
  background: #c3c7d0;
}

.adjust-price .list-box .empty {
  margin-top: 6.25rem;
}

.news-header {
  width: 100%;
  height: 10rem;
  position: relative;
  background-image: linear-gradient(to right, #c9e0ff 0%, #5ba1f6 100%);
}

.news-header .pricelog-content {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.news-header .pricelog-content .back {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

.news-header .pricelog-content .back .icon {
  transform: translate(-0.09375rem);
}

.news-header .pricelog-content .back:active {
  background-color: rgba(255, 255, 255, 0.3);
}

.news-header .pricelog-content h1,
.news-header .pricelog-content p {
  color: #fff;
}

.news-header:after {
  content: "";
  background-color: #f5f7fa;
  width: 100%;
  height: 0.625rem;
  position: absolute;
  bottom: -0.03125rem;
  border-radius: 1.25rem 1.25rem 0 0;
  transform: translateY(0.3125rem);
}

.news-header:before {
  content: "";
  width: 100%;
  height: 10rem;
  background-image: url(/umobile/assets/header-b33d853d.jpg);
  background-size: 23.4375rem 13.125rem;
  background-repeat: no-repeat;
  background-position: 0 -3.125rem;
  display: block;
  position: absolute;
  top: 0;
}
</style>
