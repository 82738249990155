var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
  ])},[_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('div',[_c('el-menu',{staticClass:"m-menu-class",attrs:{"unique-opened":true},on:{"open":_vm.handleOpen}},_vm._l((_vm.catesList),function(item,index){return _c('el-submenu',{key:index,attrs:{"index":item.id.toString(),"name":item.id}},[_c('template',{staticStyle:{"padding-left":"0px"},slot:"title"},[_c('span',[_vm._v(_vm._s(item.categoryName))])]),_vm._l((item.children),function(value,index){return _c('el-menu-item-group',{key:index},[_c('el-menu-item',{staticStyle:{"padding-left":"20px"},attrs:{"index":value.id.toString()}},[_c('span',{on:{"click":function($event){return _vm.toCate(value)}}},[_vm._v(" "+_vm._s(value.categoryName)+" ")])])],1)})],2)}),1)],1)]),_c('el-col',{attrs:{"span":18}},[_c('div',[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.loadMore},model:{value:(_vm.loadingState),callback:function ($$v) {_vm.loadingState=$$v},expression:"loadingState"}},[_c('div',{staticClass:"main-container"},[_c('div',[_c('div',{staticClass:"kind-show goods-list"},[_c('div',{staticClass:"goods4-content"},_vm._l((_vm.goodsList),function(v,k,i){return _c('div',{key:i,staticClass:"goods-item",class:{ overlay: v.inventoryState == '3' },attrs:{"title":v.name},on:{"click":function($event){return _vm.toProductDetails(v)}}},[_c('van-row',[_c('van-col',{attrs:{"span":"8"}},[_c('el-image',{attrs:{"src":v.picUrl,"fit":_vm.fit}})],1),_c('van-col',{attrs:{"span":"14"}},[_c('div',{staticClass:"product_centent"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(v.productName))]),_c('div',[_c('span',[_c('van-tag',{attrs:{"type":"primary","color":v.productTypeColor}},[_vm._v(" "+_vm._s(v.productTypeText)+" ")])],1)]),_c('div',{staticClass:"fun"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$decimalPrice(v.price))}})])])])],1)],1)}),0)])])])])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }