import request from '@/utils/request'

/**
 * 获取首页头部导航
 * @param {*} data 
 * @returns 
 */
export function getMobileNav() {
    return request({
        url: '/api/getMobileNav',
        method: 'post',
    })
}