<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]"
  >
    <!-- 首页分类栏目 rows -->
    <div class="slide_view" v-show="module.base.s == 0">
      <van-tabs scrollspy sticky :offset-top="50" title-active-color="#1e1e1e">
        <van-tab
          :title="value.categoryName"
          v-for="(value, key, index) in catesList"
          :key="index"
        >
          <div class="recommend_view">
            <div>
              <div class="recommend_title">
                {{ value.categoryName }}
              </div>
              <div class="recommend_con rows">
                <div
                  class="recommend_item column"
                  v-for="(goods, key, index) in value.children"
                  :key="index"
                  @click="toProductList(goods)"
                >
                  <van-image round :src="goods.prcUrl" class="recommend_img" />

                  <div>{{ goods.categoryName }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      catesList: [],
      showSelectGoods: false,
    };
  },

  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
    }),
  },
  mounted() {},
  created() {
    //查收商品分类
    this.getCateList();
  },
  methods: {
    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      console.log(value);
      this.$router.push({
        path: "product_list",
        query: { categoryId: value.id, categoryName: value.categoryName },
      });
    },
  },
};
</script>

<style>
.slide_view {
  background-color: #fff;
  border-radius: 6px;
  /* margin: 10px auto 0; */
}

.slide_view::-webkit-scrollbar {
  display: none;
}
.slide_item {
  min-width: 68px;
  font-size: 13px;
  color: #5b5b5b;
  text-align: center;
}
.sel_slide {
  max-width: 76px;
  height: 26px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #f1d2a9;
  background-color: #333;
  border-radius: 13px;
  margin-right: 18px;
}
.recommend_view {
  /* width: 347px; */
  background-color: #fff;
  border-radius: 6px;
  margin: 1px auto 0;
  box-sizing: border-box;
}
.recommend_title {
  font-size: 13px;
  color: #1e1e1e;
  padding-left: 18px;
  padding-top: 13px;
  font-size: 0.875rem;
  color: #191e2e;
  font-weight: 550;
}
.recommend_con {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  /* padding: 0 10px; */
  margin-top: 20px;
}
.recommend_item {
  max-width: 25%;
  width: 25%;
  font-size: 13px;
  color: #545454;
  padding-bottom: 22px;
}
.recommend_img {
  width: 41px;
  height: 41px;
  margin-bottom: 6px;
}

.image-view-area,
.image-view-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.image-view-img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}

.rows {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.between {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* 加载更多数据 */
.loading {
  font-size: 15px;
  color: #a6a6a6;
  text-align: center;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.more_icon {
  width: 4vw;
  height: 4vw;
  margin-right: 2vw;
  animation: circle 1s linear infinite;
  -webkit-animation: circle 1s linear infinite;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: initial;
}

/* 搜索框 */

/* 弹框 */
.notice-popup-content {
  padding: 20px;
  text-align: center;
}

.notice-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notice-text {
  color: #999;
  line-height: 32px;
}

.choice_img {
  width: 100%; /* 设置图片宽度为父容器的宽度 */
  height: auto; /* 自适应高度，保持原始宽高比例 */
  aspect-ratio: 12/9; /* 设置宽高比例为 16:9 （根据实际情况进行调整）*/
}
</style>
