var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
  ])},[_c('div',[_c('div',{staticClass:"today-goods-container"},[_c('div',{staticClass:"title",staticStyle:{"font-size":"14px","font-weight":"600"}},[_vm._v(" 推荐商品 "),_c('router-link',{attrs:{"to":"/hotProduct"}},[_c('span',{staticClass:"title-text"},[_c('span',[_vm._v("查看更多"),_c('van-icon',{attrs:{"name":"arrow"}})],1)])])],1),_c('div',{staticClass:"today-scroll-box"},[_c('div',{staticClass:"today-list-container"},_vm._l((_vm.recommendLists),function(item,index){return _c('div',{key:index,staticClass:"today-item",on:{"click":function($event){return _vm.toProductDetails(item)}}},[_c('img',{staticClass:"today-image",attrs:{"src":item.picUrl,"alt":"img"}}),_c('div',{staticClass:"title-name"},[_vm._v(_vm._s(item.productName))]),_c('div',{staticClass:"money"},[_vm._v(_vm._s(item.price))])])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }