<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
      { 'border-radius': module.base.round + 'px' },
    ]"
  >
    <div :style="[{ 'border-radius': module.base.round + 'px' }]">
      <!-- 导航 -->
      <van-grid :column-num="5" :border="false">
        <van-grid-item v-for="item in menuList" :key="item.id">
          <a :href="item.menuUrl">
            <van-badge :content="item.mentuBadge">
              <div style="text-align: center">
                <van-image
                  round
                  :src="item.menuIconImg"
                  style="width: 48px; height: 48px; border-radius: 5px"
                />

                <!-- 自定义文字 -->
                <div
                  style="font-size: 12px; color: #303133; text-align: center"
                >
                  {{ item.menuName }}
                </div>
              </div>
            </van-badge>
          </a>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import { queryMenuList } from "@/api/index";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      menuList: [],
    };
  },

  created() {
    //查询首页Nav导航商品
    this.getMenuList();
  },

  methods: {
    //查询首页Nav导航商品
    getMenuList() {
      new Promise((resolve, rejust) => {
        queryMenuList({})
          .then((res) => {
            this.menuList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>

<style>
.classify_view {
  /* width: 347px; */
  /* height: 334rpx; */
  background-color: #fff;
}
</style>
