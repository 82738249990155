var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
    { 'border-radius': _vm.module.base.round + 'px' },
  ])},[_c('div',{style:([{ 'border-radius': _vm.module.base.round + 'px' }])},[_c('van-grid',{attrs:{"column-num":5,"border":false}},_vm._l((_vm.menuList),function(item){return _c('van-grid-item',{key:item.id},[_c('a',{attrs:{"href":item.menuUrl}},[_c('van-badge',{attrs:{"content":item.mentuBadge}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('van-image',{staticStyle:{"width":"48px","height":"48px","border-radius":"5px"},attrs:{"round":"","src":item.menuIconImg}}),_c('div',{staticStyle:{"font-size":"12px","color":"#303133","text-align":"center"}},[_vm._v(" "+_vm._s(item.menuName)+" ")])],1)])],1)])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }