<template>
  <div>
    <el-card shadow="never">
      <!-- 按钮 -->
      <div style="padding: 10px 0px">
        <el-button type="primary" size="medium" @click="handleCreate"
          >新增</el-button
        >
      </div>

      <el-table :data="listData" border style="width: 100%">
        <el-table-column prop="locationName" label="广告标题" align="center">
        </el-table-column>
        <el-table-column prop="clickUrl" label="链接地址" align="center">
        </el-table-column>
        <el-table-column prop="uploadfileUrl" label="图片" align="center">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.uploadfileUrl"
              style="width: 100px; height: 100px"
            ></el-image>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handleUpdate(scope.row)"
              type="text"
              size="small"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="deleteBanner(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- <a-table
      bordered
      :columns="columns"
      :data-source="listData"
      :pagination="false"
    >
      <span slot="uploadfileUrl" slot-scope="text, record, index">
        <img :src="record.uploadfileUrl" style="width: 120px" />
      </span>
      <span slot="operation" slot-scope="text, record">
        <a-button type="link" @click="handleUpdate(record)">编辑</a-button>
        <a-button type="link" @click="deleteBanner(record)">删除</a-button>
      </span>
    </a-table> -->
    <!-- <div style="float: right; margin: 16px 0">
      <pagination
        :total="total"
        :current-page="queryParam.pageNum"
        :page-size="queryParam.pageSize"
        @currentChange="handleCurrentChange"
        @editPagesizes="editPageSizes"
      />
    </div> -->

    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
      <el-form label-position="top">
        <el-form-item label="广告标题">
          <el-input
            v-model="dataForm.locationName"
            placeholder="请输入广告标题"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="链接地址 (请带上http://  例如:http://www.baidu.com)"
        >
          <el-input
            v-model="dataForm.clickUrl"
            placeholder="请输入链接地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="图片上传">
          <el-upload
            class="avatar-uploader"
            action="/api/upload/public_pic"
            list-type="picture-card"
            :headers="headers"
            :on-success="UploadOnSuccess"
            :before-upload="beforeAvatarUpload"
            :limit="100"
            :show-file-list="false"
          >
            <img
              v-if="dataForm.uploadfileUrl"
              :src="dataForm.uploadfileUrl"
              class="avatar"
              style="width: 100%"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <a-modal
      :title="formTitle"
      v-model:dialogVisible="dialogVisible"
      @ok="handleClose"
      :modal-append-to-body="false"
    >
      <el-form>
        <el-form-item label="广告标题">
          <el-input
            v-model="dataForm.locationName"
            placeholder="请输入广告标题"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="链接地址 (请带上http://  例如:http://www.baidu.com)"
        >
          <el-input
            v-model="dataForm.clickUrl"
            placeholder="请输入链接地址"
          ></el-input>
        </el-form-item>

        <a-form-item label="图片上传">
          <div @click="$refs.HImage.handleAdd()">
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              disabled
            >
              <img
                v-if="dataForm.uploadfileUrl"
                :src="dataForm.uploadfileUrl"
                style="width: 150px; height: 150px"
              />
              <el-button v-else size="small" type="primary" v-else
                >点击上传</el-button
              >
            </el-upload>
          </div>
        </a-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </a-modal> -->
  </div>
</template>

<script>
import { getBannerList, addBanner, updateBanner, delBanner } from "@/api/vip";
import { getToken, getUserId } from "@/utils/auth";

export default {
  data() {
    return {
      headers: {
        token: getToken(),
        user_id: getUserId(),
      },
      formTitle: undefined,
      columns: [
        {
          title: "广告标题",
          dataIndex: "locationName",
          align: "center",
        },
        {
          title: "链接地址",
          dataIndex: "clickUrl",
          align: "center",
        },
        {
          title: "图片",
          dataIndex: "uploadfileUrl",
          scopedSlots: { customRender: "uploadfileUrl" },

          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          align: "center",
        },
      ],

      listData: [],
      total: 0,
      dialogVisible: false,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        banType: "PC",
      },
      dataForm: {
        id: undefined,
        locationName: undefined,
        clickUrl: undefined,
        uploadfileId: undefined,
        uploadfileUrl: undefined,
        banType: "PC",
      },
    };
  },
  computed: {},
  created() {
    console.log(this.banType);
    this.getList();
    this.queryParam.banType = this.banType;
    this.dataForm.banType = this.banType;
  },
  methods: {
    //查询列表
    async getList() {
      this.queryParam.banType = "PC";

      var res = await getBannerList(this.queryParam);
      this.listData = res.result.list;
      this.total = res.result.total;
    },

    /**
     * 新增
     */
    handleCreate() {
      this.formTitle = "新增";
      this.dataForm = {
        locationName: undefined,
        clickUrl: undefined,
        uploadfileId: undefined,
        uploadfileUrl: undefined,
        banType: "PC",
      };

      this.dialogVisible = true;
    },

    /**
     * 编辑
     */
    handleUpdate(row) {
      this.dataForm = row;
      this.formTitle = "修改";
      this.dialogVisible = true;
    },

    /**
     * 保存提交
     */
    async submitForm() {
      if (this.dataForm.id == null) {
        await addBanner(this.dataForm);
        this.$message.success("添加成功");
      } else {
        await updateBanner(this.dataForm);
        this.$message.success("修改成功");
      }
      this.getList();
      this.dialogVisible = false;
    },

    /**
     * 删除卡分类
     */
    async deleteBanner(row) {
      await delBanner(row.id);
      this.getList();
    },

    /**
     * 搜索查询
     */
    searchAction() {
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.banType = "PC";

      this.getList();
    },

    /**
     * 重置查询参数
     */
    resetQuery() {
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.banType = "PC";

      this.getList();
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.getList();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.getList();
    },

    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    },
    UploadOnSuccess(res) {
      console.log("---------------" + res);
      this.$set(this.dataForm, "uploadfileId", res.id);
      this.$set(this.dataForm, "uploadfileUrl", res.url);

      // this.$forceUpdate();
    },
  },
};
</script>
