var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
  ])},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.module.base.s == 0),expression:"module.base.s == 0"}],staticClass:"slide_view"},[_c('van-tabs',{attrs:{"scrollspy":"","sticky":"","offset-top":50,"title-active-color":"#1e1e1e"}},_vm._l((_vm.catesList),function(value,key,index){return _c('van-tab',{key:index,attrs:{"title":value.categoryName}},[_c('div',{staticClass:"recommend_view"},[_c('div',[_c('div',{staticClass:"recommend_title"},[_vm._v(" "+_vm._s(value.categoryName)+" ")]),_c('div',{staticClass:"recommend_con rows"},_vm._l((value.children),function(goods,key,index){return _c('div',{key:index,staticClass:"recommend_item column",on:{"click":function($event){return _vm.toProductList(goods)}}},[_c('van-image',{staticClass:"recommend_img",attrs:{"round":"","src":goods.prcUrl}}),_c('div',[_vm._v(_vm._s(goods.categoryName))])],1)}),0)])])])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }