<template>
  <div class="my">
    <div class="header-my-top">
      <!-- 未登录 -->
      <div class="top-head" v-if="!this.loginStatus">
        <img
          class="avatarUrl"
          src="@/assets/image/Juicy-1.png"
          draggable="false"
        />
        <div class="detail">
          <div class="info">
            <van-button type="info" size="normal" round @click="login"
              >登录更精彩~</van-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 已经登录 -->
    <div style="background-color: #f2e1b4" v-if="this.loginStatus">
      <div class="hhy-user-home">
        <div>
          <el-upload
            class="upload-demo"
            ref="upload"
            action="/api/user/uploadimage"
            :http-request="handleUploadHttpRequest"
            :show-file-list="false"
            :auto-upload="true"
          >
            <div class="avatar-item">
              <img :src="avatarPicUrl" alt="" class="avatar" />
              <p class="avatar-title">修改头像</p>
            </div>
          </el-upload>
        </div>
        <div class="fa">
          <div class="name">{{ username }}</div>
          <div style="margin-top: 8px">
            <span class="levelLabel">编号: {{ userid }} </span>
            <span class="levelLabel" style="margin-left: 10px">{{
              levelName
            }}</span>
          </div>
        </div>
        <div class="fr" @click="toSetup()">
          <van-icon name="setting-o" size="25" />
        </div>
      </div>

      <div class="cash-boy" v-if="this.loginStatus">
        <div class="p-30">
          <router-link to="/userlevel">
            <div class="cash-info flex-between px-30 flex-vertical-center">
              <div class="cash-num">
                <div>
                  <img
                    src="@/assets/image/zhuanshi.png"
                    style="width: 22px; margin-right: 5px"
                  />提升等级 享受更多折扣
                </div>
                <!-- <div class="text-50">{{ balanceTotal }}</div> -->
              </div>
              <div class="flex operate" style="color: white">
                <div>立即升级 <i class="el-icon-arrow-right"></i></div>
                <!-- <div>钱包</div> -->
                <!-- <div
                class="operate-item"
                style="color: white"
                @click="toBalance()"
              >
                钱包
              </div> -->
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="my-card" v-if="this.loginStatus">
      <div style="background: #ffffff; padding: 5px 0px 5px 0px">
        <van-grid :border="false" :column-num="4">
          <van-grid-item @click="toBalance()">
            <span class="amunot_title">
              {{ balanceTotal }}
            </span>
            <span class="amunot_text">账户余额</span>
          </van-grid-item>

          <van-grid-item @click="toCommission()">
            <span class="amunot_title">
              {{ balanceAllowTransfer }}
            </span>
            <span class="amunot_text">佣金</span>
          </van-grid-item>

          <van-grid-item to="/collectlist">
            <span class="amunot_title">
              {{ collectNum }}
            </span>
            <span class="amunot_text">收藏</span>
          </van-grid-item>

          <van-grid-item to="/browselist">
            <span class="amunot_title">
              {{ browseNum }}
            </span>
            <span class="amunot_text">历史浏览</span>
          </van-grid-item>
        </van-grid>

        <!-- <div style="padding: 0px 20px 0px 20px">
          <van-button type="info" size="normal" round block plain
            >钱包</van-button
          >
        </div> -->
      </div>
    </div>

    <div class="d-open-vip" style="padding: 15px 15px" v-if="this.loginStatus">
      <router-link to="/openVip" style="text-decoration: none">
        <div class="d-open-vip-inner" style="color: rgb(106, 71, 20)">
          <div class="inner-icon">
            <img src="@/assets/image/vip.png" style="width: 30px" />
          </div>
          <div class="inner-text">
            <div>开通分站 分享赚钱 自购省钱</div>
          </div>
          <div class="inner-button">
            <div class="inner-button-text">立即开通</div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="aui-boy" v-if="this.loginStatus">
      <div class="hhy-order">
        <div class="my_fiex">
          <span class="my_fiex-box"> 我的订单 </span>
          <div class="line"></div>
          <div class="aui-arrow" @click="toMyOrder(undefined)">
            <div style="color: #9b9fa8; font-size: 0.75rem; gap: 0.375rem">
              查看全部订单<van-icon name="arrow" />
            </div>
          </div>
        </div>
        <div class="aui-grids">
          <div class="aui-grids-well" @click="toMyOrder(0)">
            <div class="user-order-img">
              <img class="order_img" src="@/assets/image/icon_dcl.png" alt="" />
            </div>
            <p class="aui-grids-well-text">等待处理</p>
          </div>
          <div class="aui-grids-well" @click="toMyOrder(1)">
            <div class="user-order-img">
              <img class="order_img" src="@/assets/image/icon_clz.png" alt="" />
            </div>
            <p class="aui-grids-well-text">正在处理</p>
          </div>
          <div class="aui-grids-well" @click="toMyOrder(2)">
            <div class="user-order-img">
              <img class="order_img" src="@/assets/image/icon_ywc.png" alt="" />
            </div>
            <p class="aui-grids-well-text">交易成功</p>
          </div>
          <div class="aui-grids-well" @click="toMyOrder(4)">
            <div class="user-order-img">
              <img class="order_img" src="@/assets/image/icon_tk.png" alt="" />
            </div>
            <p class="aui-grids-well-text">退款订单</p>
          </div>
          <div class="aui-grids-well" @click="toMyOrder(undefined)">
            <div class="user-order-img">
              <img
                class="order_img"
                src="@/assets/image/icon_qbdd.png"
                alt=""
              />
            </div>
            <p class="aui-grids-well-text">全部订单</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.loginStatus">
      <div class="order-box" v-if="this.loginStatus">
        <div class="title-box">
          <div class="title">常用功能</div>
        </div>

        <div class="line"></div>

        <div class="order-box-content">
          <div class="cash-detal-btn" @click="toAfterSales()">
            <img class="icon" src="@/assets/user/tousu.png" draggable="false" />
            售后订单
          </div>

          <div class="cash-detal-btn" @click="toAmountDetail()">
            <img class="icon" src="@/assets/user/zijin.png" draggable="false" />
            资金明细
          </div>

          <div class="cash-detal-btn" @click="toSysNotice()">
            <img
              class="icon"
              src="@/assets/user/gonggao.png"
              draggable="false"
            />
            系统公告
          </div>

          <div class="cash-detal-btn" @click="toRouter('/minvited')">
            <img
              class="icon"
              src="@/assets/user/fenxiang.png"
              draggable="false"
            />
            分享赚钱
          </div>

          <div class="cash-detal-btn" @click="toRouter('/receivingAddress')">
            <img
              class="icon"
              src="@/assets/user/shouhuo.png"
              draggable="false"
            />
            收货地址
          </div>

          <div class="cash-detal-btn" @click="toVipAdmin()">
            <img
              class="icon"
              src="@/assets/icon/vip_icon.png"
              draggable="false"
            />
            我的分站
          </div>

          <div class="cash-detal-btn" @click="toRouter('/api')">
            <img class="icon" src="@/assets/icon/api.png" draggable="false" />
            开放接口
          </div>

          <div class="cash-detal-btn" @click="toRouter('/mbalance')">
            <img
              class="icon"
              src="@/assets/icon/chongzhi.png"
              draggable="false"
            />
            充值
          </div>
          <div class="cash-detal-btn" @click="toRouter('/mwithdrawal')">
            <img
              class="icon"
              src="@/assets/icon/tixian.png"
              draggable="false"
            />
            提现
          </div>
        </div>
      </div>
      <div style="height: 70px"></div>
    </div>

    <!-- 未登录支持使用的功能 -->
    <div class="order-box" v-if="!this.loginStatus">
      <div class="title-box">
        <div class="title">常用功能</div>
      </div>

      <div class="line"></div>

      <div class="order-box-content">
        <div class="cash-detal-btn" @click="toQueryOrder">
          <img class="icon" src="@/assets/image/order.png" draggable="false" />
          查询订单
        </div>
      </div>
    </div>

    <!-- <van-tabbar active-color="#ee0a24" inactive-color="#000">
      <van-tabbar-item @click="toIndex()">
        <span>首页</span>
        <template #icon>
          <img src="@/assets/icon/home.png" style="width: 24px; height: 24px" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item to="/cate">
        <span>分类</span>
        <template #icon>
          <img
            src="@/assets/icon/fenlei.png"
            style="width: 24px; height: 24px"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item icon="friends-o" size="30" to="/contact">
        <span>客服</span>
        <template #icon>
          <img src="@/assets/icon/kefu.svg" style="width: 24px; height: 24px" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="manager-o" size="10">
        <span>我的</span>
        <template #icon>
          <img
            src="@/assets/icon/wode.png"
            style="width: 28px; height: 28px"
          /> </template
      ></van-tabbar-item>
    </van-tabbar> -->

    <!-- <van-dialog
      v-model="bannerStatus"
      title="扫码进群优先享福利"
      show-cancel-button
    >
      <img :src="bannerImg" style="width: 100%" />
    </van-dialog>

    <van-dialog v-model="kefuStatus" title="您的专属VIP通道" show-cancel-button>
      <img :src="this.$store.state.customerImg" style="width: 100%" />
    </van-dialog> -->

    <NavModule />
  </div>
</template>
<script>
import { getToken } from "@/utils/auth"; // get token from cookie
import { mapState } from "vuex";
import { Toast } from "vant";
import { avatar } from "@/api/user";
import NavModule from "@/pages/mobile/common/navModule.vue";
import { getVipAddress } from "@/api/vip";

export default {
  components: { NavModule },

  data() {
    return {
      //       // loadingStatus:false,
      //       customerImg:storage.get('customerImg'),
      //       kefuStatus:false,
      //       bannerStatus:false,
      //       bannerImg:'',
      //       active: 1,
      //       goodsList:[],
      //       queryParam: {
      //         userId: undefined
      //       },
      //       withdrawMap:[],
      //       nickName:''
      loginStatus: false,
    };
  },
  computed: {
    ...mapState({
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      balanceAllowTransfer: (state) => state.balance.balanceAllowTransfer,
      branchId: (state) => state.user.branchId,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      userid: (state) => state.user.userid,
      levelName: (state) => state.user.levelName,
      collectNum: (state) => state.user.collectNum,
      browseNum: (state) => state.user.browseNum,
    }),
  },

  created() {
    //查询我的信息
    this.$store.dispatch("user/info").then();
    //查询我的余额
    this.$store.dispatch("balance/detail");

    //用户是否登录
    this.queryUserIsLogin();
  },
  methods: {
    // 修改头像
    async handleUploadHttpRequest(param) {
      const fileObj = param.file;
      const response = await avatar(fileObj);
      console.log(response);
      if (response.status == 0) {
        this.$store.commit("user/SET_AVATAR", response.result);
        // this.avatarPicUrl =
        // this.reasonImageListId.push(response.result.id)
      } else {
        this.$message({
          message: response.message,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },

    handleLogout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          this.$router.push({ path: "/login" });
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "warning",
          });
        });
    },

    /**
     * 跳转到分站后台
     */
    toVipAdmin() {
      if (this.branchId) {
        new Promise((resolve, rejust) => {
          getVipAddress()
            .then((res) => {
              let url = res.result.url + "/viplogin";
              setTimeout(() => window.open(url, "_blank"));
              resolve();
            })
            .catch((error) => {
              rejust(error);
            });
        });
      } else {
        this.$router.push("/openVip");
      }
    },

    /**
     * 判断用户是否登录
     */
    queryUserIsLogin() {
      const hasToken = getToken();
      if (hasToken) {
        this.loginStatus = true;
      }
    },

    //     /**
    //      * 注销
    //      */
    //     ...mapActions(['Login', 'Logout']),
    //     zhuxiao(){
    //          Toast({
    //               message: '退出登录',
    //               position: 'top',
    //             })
    //             this.Logout().then((response) => {
    //              this.$router.push('/login')
    //             })
    //     },

    //     /**
    //      * 联系客服图
    //      */
    //     lianxikefu(){
    //        this.kefuStatus = true
    //     },

    //   /**
    //    * 查询加群图
    //    */
    //     queryBanner(){
    //     const dictCode = 124
    //       getInfo(dictCode).then((response) => {
    //         this.bannerImg  = response.data.dictValue
    //         this.bannerStatus = true
    //       })
    //     },

    //     //跳转到分类页面
    //     toGoodsClassify() {
    //       this.$router.push('/goodsClassify')
    //     },
    toAmountDetail() {
      this.$router.push("/amountDetail");
    },

    /**
     * 登录
     */
    login() {
      this.$router.push("/login");
    },

    //跳转到客服
    toContact() {
      this.$router.push("/contact");
    },

    /**
     * 跳转到佣金界面
     */
    toCommission() {
      this.$router.push("/commission");
    },

    //跳转到售后订单
    toAfterSales() {
      this.$router.push("/afterSales");
    },

    //跳转帮助中心
    toBangZhuZhongXin() {
      this.$router.push("/bangzhuzhongxin");
    },

    //跳转系统公告
    toSysNotice() {
      this.$router.push("/sysNotice");
    },

    /**
     * 路由跳转
     */
    toRouter(router) {
      this.$router.push(router);
    },

    //     /**
    //      * 根据用户id岗位
    //      */
    //     getUserPost(){
    //         getUserInfo().then((response) => {
    //           if(response.code ==200){
    //             this.nickName = response.data.nickName
    //             this.myEarnings()
    //           }
    //         })
    //     },

    //     // 查询我的收益/提现/已提现/提现中
    //     myEarnings() {
    //       //  this.loadingStatus = true
    //       myEarnings(this.queryParam).then((response) => {
    //         this.withdrawMap = response
    //         // this.loadingStatus = false
    //       })
    //     },

    //    // 查询猜你喜欢商品
    //     getGoodsList() {
    //       queryMaxGoodsList().then((response) => {
    //         this.goodsList=response.rows
    //       })
    //     },

    toIndex() {
      this.$router.push("/mindex");
    },

    // 我的订单
    toMyOrder(v) {
      // this.$router.push("/order");

      this.$router.push({ path: "/order", query: { statusDelivery: v } });
    },

    //查询订单
    toQueryOrder() {
      this.$router.push("/queryOrder");
    },

    //跳转收益明细
    toRevenueDetail() {
      this.$router.push("/revenueDetail");
    },

    //跳转我的推广页面
    toTuiguang() {
      this.$router.push("/tuiguang");
    },

    //     //我的团队
    //     toMyItem(){
    //       this.$router.push('/item')
    //     },
    //     //跳转到我的页面
    //     toWithdrawalLog() {
    //       this.$router.push('/withdrawalLog')
    //     },

    //跳转到常见问题
    toProblem() {
      this.$router.push("/problem");
    },

    //提现
    toWithdIndex() {
      // if(this.withdrawMap.userMoney >0){
      //       this.$router.push({ name: 'withdrawal'})
      // }else{
      Toast.fail("余额不足");

      // }
    },

    //跳转我的余额
    toBalance() {
      this.$router.push("/mbalance");
    },

    //跳转到设置
    toSetup() {
      this.$router.push("/setup");
    },
    //     // 跳转到详情页面
    //     toProductDetails(value) {
    //             this.$router.push({ name: 'indexList', query: { goodsId: value.goodsId } })
    //     },

    //跳转到开店页面
    openShop() {
      this.$router.push("/openshop");
    },
  },
};
</script>
<style>
.my_fiex {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px;
  position: relative;
}
.my_fiex-box {
  -webkit-box-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.my_fiex-box span {
  font-size: 18px;
  font-weight: 500;
  font-weight: 600;
  color: #333;
  line-height: 16px;
  /* color: #333; */
}
.my_fiex-box p {
  font-size: 0.8rem;
  color: #9399a5;
}
.aui-room-user {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.aui-room-user img {
  width: 65px;
  height: 65px;
  display: block;
  border: none;
  border-radius: 100%;
}

.dj {
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.25rem;
  background: #fed4b7;
  border-radius: 0.42rem;
}
.dj img {
  width: 0.42rem;
  height: 0.42rem;
}
.dj span {
  padding: 0 0.25rem 0 0.14rem;
}

/* 头像 */
.header-my-top {
  background-color: #f2e1b4;
  padding: 0 15px;
}

.header-my-top .top-head {
  position: relative;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
}

.header-my-top .top-head .avatarUrl {
  width: 49px;
  height: 49px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 7px;
}

.header-my-top .top-head .detail {
  flex: 1;
  padding-left: 14px;
}

.header-my-top .top-head .detail .info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-my-top .top-head .detail .info .nick-name {
  font-size: 17px;
  font-weight: 600;
  color: #292b35;
  line-height: 27px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-my-top .top-head .detail .user-id {
  font-size: 13px;
}

.non-owner-header {
  height: 43px;
}

.non-owner-orders {
  margin-top: -47px;
}

/* 我的服务 */
.order-box {
  position: relative;
  z-index: 1;
  margin: 15px 15px;
  border-radius: 7px;
  background: #fff;
  padding-bottom: 9px;
}

.order-box .title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
}

.order-box .title {
  /* font-size: 0.33rem; */
  font-weight: 600;
  color: #333;
  line-height: 16px;
}

.order-box .title-box .image {
  display: block;
  width: 15px;
  height: 15px;
}

.order-box .line {
  background: #f5f5f5;
  height: 1px;
  margin: 0 15px;
}

.order-box .order-box-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 4px;
}

.order-box .cash-detal-btn {
  position: relative;
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #666;
  padding: 11px 0;
  font-size: 12px;
  line-height: 16px;
}

.order-box .icon {
  margin-bottom: 9px;
  width: 32px;
  height: 32px;
}

.my {
  width: 100vw;
  min-height: 100vh;
  /* background-color: #f8f8f8 */
}

.my .user {
  width: 100%;
  padding: 0 0.35rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.my .user .title {
  height: 1.8rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.my .user .title,
.my .user .title .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my .user .userImg {
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 1rem;
}

.my .user .userName {
  margin-left: 0.3rem;
  font-size: 0.4rem;
  font-weight: 600;
  color: #36343a;
}

.my .user .progress {
  padding: 0.35rem 0.35rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my .user .progresTtip {
  margin-top: 0.15rem;
  padding-left: 0.35rem;
}

.my .amount {
  margin: 15px 15px;
  /* margin-top: -40px; */
}

.my .amount .amountIn {
  background: #fff;
  border-radius: 7px;
  /* -webkit-box-shadow: 5px 5px 7px 5px rgba(96, 83, 75, .05); */
  /* box-shadow: 0 5px 7px 0 rgba(96, 83, 75, .05); */
  padding: 15px 15px;
  color: #333;
}

.my .amount .myProfit .myProfitTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 0.8rem;
}

.my .amount .myProfit .money {
  /* font-size: .47rem; */
  line-height: 0.7rem;
  margin-bottom: 0.1rem;
}

.my .amount .moneyItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
}

.my .amount .moneyItem div {
  width: 15%;
}

.my .amount .moneyItem div span {
  display: block;
  color: #666;
  /* font-size: .28rem */
}

.my .amount .moneyItem div b {
  display: block;
  margin-top: 0.06rem;
  /* font-size: .38rem */
}

.my .amount .moneyItem div b i {
  font-style: normal;
  /* font-size: .32rem; */
  color: #666;
}

.my .amount .moneyItem div .popoverTip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my .amount .moneyItem div .popoverTip .popoverTipIcon {
  margin-left: 0.1rem;
}

.my .amount .goExtract {
  background: #f5dabc;
  height: 0.55rem;
  width: 1.4rem;
  line-height: 0.55rem;
  border-radius: 0.55rem;
  text-align: center;
  color: #2e333a;
  margin-top: 0.3rem;
  padding-left: 0.1rem;
  /* font-size: .32rem; */
}

.my .banner {
  /* height: 2rem; */
  padding: 0.15rem 0.36rem 0;
  /* margin: 0 0 .3rem; */
  position: relative;
}

.my .banner .advertisingSpace {
  width: 100%;
  height: 1.5rem;
  background-color: #fff;
  border-radius: 0.24rem;
}

.my .serveList {
  padding: 0 0.36rem;
}

.my .serveList .serveListIn {
  background: #fff;
  border-radius: 0.2rem;
  padding: 0.1rem 0.35rem;
  color: #333;
}

.my .serveList .serveListIn div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1rem;
}

.my .serveList .serveListIn div span {
  font-size: 0.3rem;
  position: relative;
  padding-left: 0.6rem;
}

.my .serveList .serveListIn div span:before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  top: 50%;
  left: -0.1rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* .my .serveList .serveListIn div .item1:before {
    background: url(@/assets/image/wodedingdan.png) no-repeat;
    background-size: 100%
}

.my .serveList .serveListIn div .item2:before {
    background: url(@/assets/image/tixianjilu.png) no-repeat;
    background-size: 100%
}

.my .serveList .serveListIn div .item3:before {
    background: url(@/assets/image/kefu.png) no-repeat;
    background-size: 100%
}

.my .serveList .serveListIn div .item4:before {
    background: url(@/assets/image/xiaji.png) no-repeat;
    background-size: 100%
} */

.my .serveList .serveListIn div .tip {
  font-size: 0.32rem;
  color: #999;
}

.my .serveList .serveListIn div .arr {
  color: #a1a5b0;
  font-size: 0.46rem;
}

.my .hobby {
  margin-top: 0.4rem;
  padding: 0 0.3rem;
}

.my .hobby .topName {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my .hobby .topName > img {
  width: 2.853334rem;
  height: 0.26667rem;
}

.my .hobby .topName > div {
  font-size: 0.3rem;
  padding: 1px 0.2rem;
  font-weight: 500;
  color: #36343a;
}

.my .hobby .productList {
  height: 100px;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.my .hobby .productList,
.my .hobby .productList .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}

.my .hobby .productList .column {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 1.5rem;
  width: 48.5%;
}

.my .hobby .productList .cardBag {
  /* width: 4.5rem; */
  position: relative;
  padding-bottom: 0.266667rem;
  margin-top: 0.266667rem;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 8px 0 rgba(96, 83, 75, 0.05);
  /* box-shadow: 0 5px 8px 0 rgba(96, 83, 75, .05); */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.my .hobby .productList .cardBag > img {
  /* height: 2.95rem; */
  width: 100%;
  border-radius: 7px;
  display: block;
}

.my .hobby .productList .cardBag .cardBagInfo {
  padding: 0 0.2rem;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.my .hobby .productList .cardBag .cardBagInfo .name {
  font-size: 0.3rem;
  font-weight: 600;
  color: #333;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 0.6rem;
  margin-top: 0.15rem;
}

.my .hobby .productList .cardBag .cardBagInfo .ehhylain {
  color: #999;
  font-size: 0.18rem;
  font-weight: 400;
  margin-top: 1px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.my .hobby .productList .cardBag .cardBagInfo .priceAggregate .price {
  display: block;
  color: #ff2b21;
  font-size: 0;
  margin-top: 0.2rem;
}

.my
  .hobby
  .productList
  .cardBag
  .cardBagInfo
  .priceAggregate
  .price
  > span:first-child {
  font-size: 0.2rem;
  font-weight: 500;
  text-shadow: 0 4px 11px 0 rgba(188, 135, 104, 0.44);
}

.my
  .hobby
  .productList
  .cardBag
  .cardBagInfo
  .priceAggregate
  .price
  > span:nth-child(2) {
  font-size: 0.38rem;
  font-weight: 600;
}

.my
  .hobby
  .productList
  .cardBag
  .cardBagInfo
  .priceAggregate
  .price
  > span:nth-child(3) {
  font-size: 0.293334rem;
  font-weight: 600;
}

.my .hobby .productList .cardBag .cardBagInfo .priceAggregate .oldPrice {
  display: inline-block;
  font-size: 0.293334rem;
  font-weight: 400;
  text-decoration: line-through;
  color: #999;
}

.my .hobby .productList .cardBag .cardBagInfo .priceAggregate .returned {
  /* display: inline-block;
    font-size: 0;
    height: .426667rem;
    line-height: .4rem;
    margin-top: .1rem */
  display: inline-block;
  position: absolute;
  right: 0.1rem;
  bottom: 0.35rem;
  font-size: 0;
  height: 0.33rem;
  line-height: 0.33rem;
  margin-top: 0.1rem;
}

.my
  .hobby
  .productList
  .cardBag
  .cardBagInfo
  .priceAggregate
  .returned
  > span:first-child {
  display: inline-block;
  width: 0.4rem;
  font-size: 0.26667rem;
  color: #f5dabc;
  font-weight: 500;
  border: 1px solid #2e333a;
  background-color: #2e333a;
  border-radius: 0.106667rem 0 0 0.106667rem;
  text-align: center;
}

.my
  .hobby
  .productList
  .cardBag
  .cardBagInfo
  .priceAggregate
  .returned
  > span:nth-child(2) {
  display: inline-block;
  font-size: 0.26667rem;
  color: #2e333a;
  font-weight: 500;
  padding-right: 2px;
  padding-left: 2px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#fcf2e8),
    to(#f7e1c9)
  );
  background: -o-linear-gradient(right, #fcf2e8, #f7e1c9);
  background: linear-gradient(270deg, #fcf2e8, #f7e1c9);
  border: 1px solid #f0d9c5;
  border-radius: 0 0.106667rem 0.106667rem 0;
}

.my .hobby .productList .cardBag .cardBagInfo .priceAggregate .returned_active {
  border: 1px solid #cc8c38;
  border-radius: 0.106667rem;
  background: transparent;
}

.my
  .hobby
  .productList
  .cardBag
  .cardBagInfo
  .priceAggregate
  .returned_active
  > span:first-child {
  color: #5a3309;
  border: 1px solid transparent;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f0d19e),
    color-stop(65%, #e6ba79)
  );
  background: -o-linear-gradient(left, #f0d19e, #e6ba79 65%);
  background: linear-gradient(90deg, #f0d19e, #e6ba79 65%);
}

.my
  .hobby
  .productList
  .cardBag
  .cardBagInfo
  .priceAggregate
  .returned_active
  > span:nth-child(2) {
  color: #5a3309;
  border: 1px solid transparent;
  background: transparent;
}

.my .hobby .productList .cardBag .cardBagInfo:after {
  display: block;
  content: "";
  clear: both;
}

.my .van-overlay {
  z-index: 9;
}

.my .dialog .content {
  width: 7.2rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.my .dialog .content .contentIn {
  width: 100%;
  height: 3rem;
  background: #fff;
  border-radius: 0.25rem;
  padding-bottom: 0.2rem;
}

.my .dialog .content .contentIn p {
  line-height: 2.1rem;
  text-align: center;
  font-size: 0.38rem;
  color: #333;
}

.my .dialog .content .contentIn span {
  width: 1.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 2rem;
  display: block;
  margin: 0 auto;
  color: #5a3309;
  font-weight: 600;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f0d19e),
    to(#e6ba79)
  );
  background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
  background: linear-gradient(90deg, #f0d19e, #e6ba79);
}

.my .dialog .content .close {
  width: 0.6rem;
  height: 0.6rem;
  display: block;
  margin: 0.4rem auto 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAAAXNSR0IArs4c6QAABGFJREFUaEPVmVvIpVMcxn9PzoqUFBE1ppgxlMyFyDQzzQUyyo1jIxqluFaEjDDJvSsycmxKKTkkzDhELiiUU8yEzHAlmZxCj56v/+b93m9/+93v3t8ne9W+2XuvtX7vfz3r/3/WesWUzfbpwEZgLXAacApwNHAk8DNwAPga+Bx4D9gl6bNpptUknW0H7DpgC3DqBGPsAR4Hdkj6pm//XtC2VwB3AtcAhzQm2w+8BXwKBOhH4FfgCOCYerBVwDrghEa/P4AngXsk7R0Xfixo24cBt9bn8Br8W+Ax4ClJH487oe01wNW1SidVv9+A+/OR9HvXWJ3QtrP8O4FzarCvEpksr6REaqJmOyt1ba1c5Jb2PnCFpKzWom0ktO31wLO1xH8CDwD3SsrSL0mznQ17B3ALcHBJ6zJJry82waLQti+tCEcO+4CrJEW3y9JsXwA8DZwIRC6J+HPDJhsKXRF+CQhw9HqhpGh4WZvtaPxlYHWBXzQs4gugS8PJp9n1AV4n6YdlpW0MbvtY4M0CTxZa29b4POjKEm/hhyoskzv0vItwOSEX83ZJKNuf5zazShr4L2AZk021cTg13rZzt5PTXanNuk3T3oM8/0FU4IofoeLuk27sGXu7fbW8Hbit9nzEoQE3oHVWak4dXL2Vam/ThKh1+Un7mUUnXZ6w5aNsnA19Wad4q6ZGuiWzfV0Zpi6T0HbuVyXohJkrSxaM62t4KPAykkK2MVxlAD7SctLZinEpn+0PgLCB9NowLXsC7geOBvZJGGi7bh+Z/tSnntD2ATqTSeWwt2w7wq8Bx44IX8K4yTd/VZu+0qQ1t75G0UjVQ3Fnamp7m58za4Z3gLeDva3U6gUu+meejYlwV6JuAB4H9klJCezXbneDTAA9gbMf+xtbeHOhsuuzKnZKu7EVcfx4FbjunmWg4E/aKcJPFdpzm5XMHB9upgOelqDQTeF/4YeDAQS3gFKyBFHtNYTtFLwnjnUBn90cWSV1P9Bqp9ecy+NloA40HehDhiYFL1zna5dCxL9A/AUcBmyU9Pw10DZ6TyQA8X0USUwHXuJuBWNUDgY7PSEQ2SUqtn6q1NJyxspLru04jXZPa3gS8Avy1pJFuZYns9hypOtNhF/CwSC+JpocUjg1AqllWb2pw2/M0PXX2GAYsKZcz8TWdeXzMSM/LHlPl6ZaXSGmOD5kDbhSG5ubM4SIa72uy5uhhyiSui7VzAJFPE/Iz0Eq10GPA83BfjRLlWbF5FzF1cb+/RAh6r0k0KXhL713vUU0zi8nKhkmuysYAXkcoHks7uivYCl1fQk/jpF+uW9JK+pbki/gzwhqQbR0GP8tO9Ty5d0Vmq323fADy04ORS0Z6tM2JBR5//19N47g5zQJm7Dp7te4+Kdu6hZ+uGqcBzwJ2du7xGLs299OzcmjbAZ+t+uhXx2XkT0ACfrXcuDfDZervV8sWz8x6x7SNm6o3tMBNUvjrnwWHvxn8BckXRfDe+u68rbM/7N3hfn7e35TXfAAAAAElFTkSuQmCC)
    no-repeat;
  background-size: 0.6rem;
}

/* 我的余额 */
.cash-boy {
  padding: 0 1.2rem;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}
.flex-vertical-center {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}

.cash-info .cash-num {
  color: #fadfdd;
  text-align: center;
}

.text-25 {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.text-50 {
  font-size: 1.3rem;
  color: #ffffff;
}

.px-30 {
  padding: 0 12px;
}
.cash-info {
  height: 52px;
  background: url(../../../assets/image/h5bg.png) no-repeat center center;
  background-size: 100% 100%;
}

/* 头像 */
.hhy-user-home {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 22px 25px;
}

.hhy-user-home .fl {
  width: 65px;
  height: 65px;
  border-radius: 35px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  background: #fff;
}

.hhy-user-home .fa {
  width: calc(100% - 140px);
  margin-left: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hhy-user-home .fa .name {
  font-size: 18px;
  font-weight: bold;
}

/* .hhy-user-home .fa .id {
  font-size: 12px;
  padding: 5px 8px;
  width: fit-content;
  background: linear-gradient(0deg, #5b5b59, #363634);
  color: #fff3dd;
  border-radius: 30px;
  margin-top: 5px;
} */

.levelLabel {
  font-size: 12px;
  background: linear-gradient(0deg, #5b5b59, #363634);
  color: #fff3dd;
  border-radius: 30px;
  padding: 5px 8px;
}

.hhy-user-home .fr {
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

/* 我的订单 */
.aui-boy {
  padding: 0 15px;
}
.hhy-order {
  width: 100%;
  /* height: 8.8rem; */
  background: linear-gradient(rgb(255, 249, 239) 0%, rgb(255, 255, 255) 100%);
  box-shadow: 2px 2px 15px 0px #f0f0f0;
  border-radius: 0.46875rem;
  border: 0.09375rem solid #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0;
}

.my_fiex {
  width: 100%;
}
.my_fiex {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px;
  position: relative;
}

.aui-arrow {
  position: relative;
  /* padding-right: 0.8rem; */
}

.aui-arrow a {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.aui-grids {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* margin-top: 15px; */
}

.aui-grids-well {
  position: relative;
  float: left;
  padding: 5px 0px 10px 0px;
  width: 20%;
  box-sizing: border-box;
}

.user-order-img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.aui-grids-well-text {
  text-align: center;
  font-size: 12px;
  padding-top: 4px;
  margin-top: 5px;
  color: #9a9a9e;
}

.order_img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

/* 新的 */

.d-account-inner {
  padding: 12px;
  background: #ffffff;
}

.d-account-inner .d-inner-left {
  display: flex;
  align-items: center;
}

.d-account-inner .d-inner-left-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.d-account-inner .d-inner-left-title {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--nut-title-color);
}

.d-account-inner .d-inner-right {
  text-align: right;
  font-size: 12px;
}

.d-account-inner .d-inner-right-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--nut-title-color2);
}

.d-account-inner .d-inner-right-title-link {
  display: flex;
  margin-left: 4px;
  flex-direction: row;
  align-items: center;
}

.d-account-inner .d-inner-right-desc {
  color: var(--nut-primary-color);
}

.d-account-inner .d-inner-account {
  color: var(--nut-title-color);
  font-weight: 700;
  font-size: 17px;
}

.d-account-inner .d-inner-text {
  color: var(--nut-title-color2);
}

/* 新的 */
.my-card {
  padding: 0px 15px 0px 15px;
}

.amunot_title {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 7px;
  display: block;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.amunot_text {
  font-size: 14px;
  color: #555;
}

/* 分站的 */

.d-open-vip-inner {
  background-image: url(../../../assets/image/fenzhan.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 56px;
  font-size: 14px;
  color: #6a4714;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-open-vip-inner .inner-icon {
  margin-right: 5px;
}

.d-open-vip-inner .inner-text {
  flex: 1;
  font-weight: 600;
}

.d-open-vip-inner .inner-button {
  margin-left: 12px;
}

.d-open-vip-inner .inner-button-text {
  background: rgba(255, 255, 255, 0.5);
  padding: 4px 17px;
  border-radius: 50px;
}

.d-order .nut-skeleton.d-order-skeleton {
  padding: 8px 0;
  width: 90%;
}

.avatar-item {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.avatar-title {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0px;
  height: 25px;
  line-height: 21px;
  background: rgba(0, 0, 0, 0.384);
  font-size: 10px;
  font-weight: 600;
  color: #fff;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
</style>
