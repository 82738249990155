<template>
  <div v-cloak>
    <template v-if="navStatus">
      <van-tabbar
        active-color="#ee0a24"
        inactive-color="#000"
        v-model="currentIndex"
      >
        <van-tabbar-item
          v-for="(item, index) in mobileNavList"
          :key="index"
          @click="toPath(item.navUrl)"
        >
          <span>{{ item.navName }}</span>
          <template #icon>
            <img
              :src="item.iconUrl"
              :style="{
                width: currentPath === item.navUrl ? '28px' : '24px',
                height: currentPath === item.navUrl ? '28px' : '24px',
              }"
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </template>

    <template v-else>
      <van-tabbar
        v-model="currentIndex"
        active-color="#ee0a24"
        inactive-color="#000"
      >
        <van-tabbar-item to="/mindex" replace>
          <span>主页</span>
          <template #icon>
            <img
              src="@/assets/icon/home.png"
              :style="{
                width: currentIndex === 0 ? '28px' : '24px',
                height: currentIndex === 0 ? '28px' : '24px',
              }"
            />
          </template>
        </van-tabbar-item>

        <van-tabbar-item to="/cate" replace>
          <span>分类</span>
          <template #icon>
            <img
              src="@/assets/icon/fenlei.png"
              :style="{
                width: currentIndex === 1 ? '28px' : '24px',
                height: currentIndex === 1 ? '28px' : '24px',
              }"
            />
          </template>
        </van-tabbar-item>

        <van-tabbar-item to="/contact" replace>
          <span>客服</span>
          <template #icon>
            <img
              src="@/assets/icon/kefu.svg"
              :style="{
                width: currentIndex === 2 ? '28px' : '24px',
                height: currentIndex === 2 ? '28px' : '24px',
              }"
            />
          </template>
        </van-tabbar-item>

        <van-tabbar-item to="/my" replace>
          <span>我的</span>
          <template #icon>
            <img
              src="@/assets/icon/wode.png"
              :style="{
                width: currentIndex === 3 ? '28px' : '24px',
                height: currentIndex === 3 ? '28px' : '24px',
              }"
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </template>
  </div>
</template>
<script>
import { getMobileNav } from "@/api/config";

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mobileNavList: [],
      navStatus: true,
      currentIndex: 0,
      currentPath: 0,
    };
  },

  mounted() {
    this.handleActive();
    this.handleActive2();

    this.loadData();
  },
  watch: {
    $route() {
      this.handleActive();
      this.handleActive2();
    },
  },
  methods: {
    //点击
    handleActive() {
      let path = this.$route.path;
      if (path.indexOf("/mindex") !== -1) {
        this.currentIndex = 0;
      } else if (path.indexOf("/cate") !== -1) {
        this.currentIndex = 1;
      } else if (path.indexOf("/contact") !== -1) {
        this.currentIndex = 2;
      } else if (path.indexOf("/my") !== -1) {
        this.currentIndex = 3;
      }
    },

    handleActive2() {
      let path = this.$route.path;
      this.currentPath = path;
    },

    /**
     * 加载数据
     */
    loadData() {
      new Promise((resolve, rejust) => {
        getMobileNav()
          .then((res) => {
            this.mobileNavList = res.result;
            if (!this.mobileNavList.length > 0) {
              this.navStatus = false;
            }
            console.log(this.navStatus);
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 跳转路径
     */
    toPath(navUrl) {
      if (navUrl.includes("http")) {
        window.open(navUrl, "_blank");
      } else {
        this.$router.push(navUrl);
      }
    },
  },
};
</script>
